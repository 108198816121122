@import "../../assets/scss/variables.scss";

.sticker {
  font-weight: 600;
  /* line-height: 97px; */
  /* identical to box height */

  color: #ffffff;
  position: absolute;

  left: $fig-col-1;
  bottom: $fig-col-1;

  @media (min-width: $breakpoint-mobile) {
    font-size: 80px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-size: 60px;
  }
}

.sticker p {
  margin: unset;
}

.first {
  position: absolute;
  transform: rotate(90deg);

  @media (min-width: $breakpoint-mobile) {
    margin-left: -35px !important;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-left: -25px !important;
  }
}

.second {
  @media (min-width: $breakpoint-mobile) {
    margin-top: 35px !important;
    margin-left: 50px !important;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-top: 25px !important;
    margin-left: 40px !important;
  }
}
