@import "../../assets/scss/variables.scss";

.card {
  cursor: pointer;
  /* Midnight Blue */

  color: #000427;
}

.card .media {
}

.card .title {
  @include font-medium;

  @media (min-width: $breakpoint-mobile) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin: 20px 0;
  }
}

.card .footer {
  @include font-small;

  color: #000427;
}

.footer p {
  max-width: 400px;
}

.footer .date {
  @media (min-width: $breakpoint-mobile) {
    margin-top: 40px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-top: 25px;
  }
}
