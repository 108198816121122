@import "../../assets/scss/variables.scss";

.card {
  /* Midnight Blue */
  color: #000427;
  margin-bottom: 20px;
  // cursor: pointer;
}

.card .title {
  @include font-medium;
}

.card .media {
  background-position: center;
  background-size: cover;
  width: 100%;

  @media (min-width: $breakpoint-mobile) {
    margin: 22px 0 30px 0;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin: 20px 0;
  }
}

.card .subtitle {
  @include font-small-semibold;

  @media (min-width: $breakpoint-mobile) {
    margin-bottom: 20px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-bottom: 10px;
  }
}

.card .description {
  @include font-small;
}
