.carousel {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  /* cursor: pointer; */
}

.carousel .left {
  height: inherit;
  width: 50%;
  position: absolute;
  left: 0;
}

.carousel .right {
  height: inherit;
  width: 50%;
  position: absolute;
  left: 50%;
}

.carousel .forward {
  height: 70%;
  width: 40%;
  position: absolute;
}

.carousel div {
  background-position: center;
  background-size: cover;
}
