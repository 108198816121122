$min-width: 360px;
$breakpoint-mobile: 768px;

$fig-col-1: 4vw;
$fig-col-2: 8vw;
$fig-col-3: 12vw;
$fig-col-4: 16vw;
$fig-col-5: 20vw;
$fig-col-6: 24vw;
$fig-col-7: 28vw;
$fig-col-8: 32vw;
$fig-col-9: 36vw;
$fig-col-10: 40vw;
$fig-col-11: 44vw;
$fig-col-12: 48vw;
$fig-col-13: 52vw;
$fig-col-14: 56vw;
$fig-col-15: 60vw;
$fig-col-16: 64vw;
$fig-col-18: 72vw;
$fig-col-19: 76vw;
$fig-col-20: 80vw;
$fig-col-21: 84vw;

$color-nude: #e7d8cb;
$color-midnight: #000427;
$color-blue: #0038ff;

@mixin font-basis() {
  font-style: normal;
  font-family: Inter;
}

@mixin font-large() {
  @media (min-width: $breakpoint-mobile) {
    font: normal 600 80px/97px Inter;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font: normal 600 60px/73px Inter;
  }
}

@mixin font-medium() {
  @media (min-width: $breakpoint-mobile) {
    font: normal 600 40px/140% Inter;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font: normal 600 30px/36px Inter;
  }
}

@mixin font-small-semibold() {
  @media (min-width: $breakpoint-mobile) {
    font: normal 600 20px/24px Inter;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font: normal 600 15px/140% Inter;
  }
}

@mixin font-small() {
  @media (min-width: $breakpoint-mobile) {
    font: normal 500 20px/145% Inter;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font: normal 500 15px/140% Inter;
  }
}

@mixin font-extra-small() {
  @media (min-width: $breakpoint-mobile) {
    font: normal 600 14px/17px Inter;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font: normal 600 12px/15px Inter;
  }
}

.pl_1 {
  padding-left: $fig-col-1;
}

.ml_1 {
  margin-left: $fig-col-1;
}

.flex_center {
  display: flex;
  align-items: center;
}

.flarge {
  @media (min-width: $breakpoint-mobile) {
    font-weight: 600;
    font-size: 80px;
    line-height: 97px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
  }

  font-family: Inter;
  font-style: normal;
}

.fmedium {
  @media (min-width: $breakpoint-mobile) {
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }

  font-family: Inter;
  font-style: normal;
}

.fboldsmall {
  @media (min-width: $breakpoint-mobile) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
  }

  font-family: Inter;
  font-style: normal;
}

.fsmall {
  @media (min-width: $breakpoint-mobile) {
    font-weight: 500;
    font-size: 20px;
    line-height: 145%;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }

  font-family: Inter;
  font-style: normal;
}

.fextrasmall {
  @media (min-width: $breakpoint-mobile) {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  font-family: Inter;
  font-style: normal;
}

@mixin font-really-small() {
  @media (min-width: $breakpoint-mobile) {
    font-size: 12px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-size: 10px;
  }
}
