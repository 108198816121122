@import "../../assets/scss/variables.scss";

.avatar {
  display: flex;
  overflow: hidden;
  position: relative;
  //   font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  //   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;

  // border: solid;
  // border-color: gray;
  // border-width: 2px;
}

.small {
  @media (min-width: $breakpoint-mobile) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    width: 25px;
    height: 25px;
  }
}

.big {
  width: $fig-col-5;
  height: $fig-col-5;
}

.text {
  text-align: center;

  @include font-small-semibold;
}
