@import "../../assets/scss/variables.scss";

.schedules {
  color: #000427;

  // font-weight: 500;
  // font-size: 20px;
  // line-height: 24px;

  p {
    margin: unset;
  }
}

.schedules a {
  color: #212529;
}

.schedules a:hover {
  color: #0038ff;
}

.schedules .title {
  @media (min-width: $breakpoint-mobile) {
    @include font-small-semibold;

    padding: 40px 0;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    @include font-medium;
  }
}

.schedules .header,
.schedules .item {
  margin: 55px 0;
  @media (min-width: $breakpoint-mobile) {
    display: flex;
    // justify-content: space-between;
  }

  @media (max-width: $breakpoint-mobile - 1) {
  }
}

.schedules .header {
  @include font-small-semibold;
}

.schedules .item {
  @include font-small;
}

.schedules .item div,
.schedules .header div {
  @media (min-width: $breakpoint-mobile) {
    // flex-basis: 30%;
  }
}

.schedules .item img {
  position: absolute;
  right: 10vw;
  transform: translateY(-50%);
}

.item .description {
  p {
    margin-bottom: 12px;
  }
}
