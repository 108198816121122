@import "../../assets/scss/variables.scss";

.fadein {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.fadeout {
  -webkit-animation: fadeout 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeout 2s forwards; /* Internet Explorer */
  -o-animation: fadeout 2s forwards; /* Opera < 12.1 */
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden; }
}

.sacredPrint {
  cursor: pointer;
  padding: 0 $fig-col-1;
}

/* Header */
.header {
  .logobar {
    padding: 0 $fig-col-1;
    background: #f0f0f0;
    backdrop-filter: blur(24px);

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;

    height: 35px;
  }
}

.logobar .logo {
  position: absolute;
}

.logobar .login {
  margin-left: $fig-col-18;
}

.navBar {
  // color: #000427;
  display: flex;
  flex-direction: Row;

  padding: 0 $fig-col-1;

  @media (min-width: $breakpoint-mobile) {
    @include font-small;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    @include font-extra-small;
  }
}
.navBar .mainLinks {
  display: flex;
  align-items: center;

  @media (min-width: $breakpoint-mobile) {
    width: $fig-col-16;
    padding: 30px 0;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    padding: 15px 0;
  }
}

.logobar a,
.navBar a {
  color: #000427;
  display: flex;
}
.navBar .mainLinks a {
  @media (min-width: $breakpoint-mobile) {
    margin-right: 50px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    // margin-right: 32px;
  }
}

.navBar .mainLinks .active {
  text-decoration: underline !important;
}

@media (max-width: $breakpoint-mobile - 1) {
  .mainLinks .first {
    width: $fig-col-12;
  }

  .mainLinks .second {
    width: $fig-col-6;
  }

  .mainLinks .third {
    width: $fig-col-6;
  }
}

.subpageLogo,
.loginLink {
  display: flex;
  align-items: center;
}
// .tsfLogo {
//   filter: drop-shadow(0px 0px 5px #fff);
// }
.subpageLogo {
  width: $fig-col-2;
}
.loginLink {
  width: $fig-col-5;
}
.subpageLogo img {
  cursor: pointer;
}

/*Footer*/
.footer .mailinglist {
  background-color: #0038ff;
  color: #fff;

  @media (min-width: $breakpoint-mobile) {
    padding: 100px $fig-col-1 150px $fig-col-1;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    font-weight: 500;
    padding: 50px $fig-col-1;
  }

  .formgroup {
    display: flex;
  }

  .formgroup .item {
    @media (min-width: $breakpoint-mobile) {
      @include font-medium;
    }
    @media (max-width: $breakpoint-mobile - 1) {
      @include font-small;
    }
  }

  .formgroup input {
    min-width: 0;
  }

  .formgroup .mailbox {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    min-width: 0;
  }
}

button.join,
button.join:hover,
button.join:active,
button.join:focus {
  background-color: transparent !important;
  border: none;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  color: white;
  text-align: left;
  cursor: pointer;
}
.mailinglist input {
  background-color: transparent;
  border: none;
  color: #fff;
  border-radius: 0px;
}
.mailinglist ::placeholder,
.mailinglist input:focus {
  color: #fff !important;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer {
  .content {
    @include font-small;

    background-color: #000427;
    position: relative;

    padding: 50px 0 50px $fig-col-1;

    @media (min-width: $breakpoint-mobile) {
      height: 815px;
    }
    @media (max-width: $breakpoint-mobile - 1) {
      height: 615px;
    }

    .group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: $breakpoint-mobile) {
      top: 50%;
    }
    @media (max-width: $breakpoint-mobile - 1) {
      top: 60%;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0px;
    left: 0;

    width: -webkit-fill-available;

    @media (min-width: $breakpoint-mobile) {
      padding: 50px 0 50px $fig-col-1;
    }
    @media (max-width: $breakpoint-mobile - 1) {
      padding: 15px 0 15px $fig-col-1;
    }
  }
}

.group a {
  color: #fff;

  @media (min-width: $breakpoint-mobile) {
    flex: 1 1 30%;
    margin-bottom: 14px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    flex: 1 1 50%;
    margin-bottom: 18px;
  }
}

.group p {
  color: #fff;

  @include font-extra-small;

  @media (min-width: $breakpoint-mobile) {
    flex: 1 1 30%;
    margin: unset;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    flex: 1 1 50%;
    margin-top: unset;
    margin-bottom: 7px;
  }
}

/* Footer end*/

/* Layout */
.background {
  z-index: -1;

  background-position: center;
  background-size: cover;
  width: 100%;

  height: 110vh;
}

.title {
  color: #000427;

  margin-left: $fig-col-1;

  @media (min-width: $breakpoint-mobile) {
    @include font-medium;
    margin-top: 15vh;
  }

  @media (max-width: $breakpoint-mobile - 1) {
    @include font-small-semibold;
    margin-top: 7vh;
  }
}

.absoluteTop {
  position: absolute;
  top: 0;
  width: 100%;
}

.top {
  top: 0;
}

.container {
  /* margin: 50px; */
  overflow-x: hidden;
}

.customUi {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #e7d8cb;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #000427;
}

.customUi > h1 {
  margin-top: 0;
  text-transform: capitalize;
}

.customUi > div {
  margin-top: 20px;
  margin-bottom: 30px;
}

.customUi > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #000427;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #000427;
  font-size: 14px;
}