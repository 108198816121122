.category {
  display: flex;
}

.category .text {
  /* margin: 0 60px;

  align-self: center; */
}

.category .media {
  /* flex: 0 0 auto; */
  background-size: cover;
  background-position: center;
}
