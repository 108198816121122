@import "../../assets/scss/variables.scss";

.intro {
  @media (min-width: $breakpoint-mobile) {
    margin-top: 30px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-top: 25px;
  }
}

.description {
  @include font-small;

  background-color: #9aacd2;
  color: #000427;

  @media (min-width: $breakpoint-mobile) {
    padding: 50px $fig-col-1;
    height: 300px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    padding: 25px $fig-col-1;
    height: 200px;
  }
}

.description p {
  margin: unset;

  @media (min-width: $breakpoint-mobile) {
    max-width: 400px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    max-width: 200px;
  }
}

.intro > .wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.intro > .wrapper > .media {
  background-position: center;
  background-size: cover;
  height: 100vh;

  @media (min-width: $breakpoint-mobile) {
    width: 50%;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    width: 100%;
  }
}

.sticker {
  position: fixed;
  bottom: 0;
}

.features {
  display: flex;
  overflow-x: auto !important;
  // cursor: grab;
  // background-attachment: local, local, grab, grab !important;

  padding: 50px $fig-col-1;

  @media (min-width: $breakpoint-mobile) {
    height: 810px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    height: 655px;
  }
}

.features .card {
  @media (min-width: $breakpoint-mobile) {
    width: $fig-col-7;
    min-width: 400px;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    width: $fig-col-20;
    min-width: 300px;
  }
}

.features .card:not(:first-child) {
  margin-left: $fig-col-1;
}

// Schedule list
.schedule {
  @media (min-width: $breakpoint-mobile) {
    padding: 50px $fig-col-1;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    padding: 50px 0 50px $fig-col-1;
  }
}

.blog {
  background-color: #e7d8cb;
  @media (min-width: $breakpoint-mobile) {
    padding: 50px $fig-col-1;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    padding: 25px $fig-col-1;
  }

  p {
    margin: unset;
  }
}

.blog .title {
  @media (min-width: $breakpoint-mobile) {
    @include font-small-semibold;
    padding: 40px 0;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    @include font-medium;
    padding: 25px 0;
  }
}

.blog .more {
  color: #000427;

  @media (min-width: $breakpoint-mobile) {
    @include font-small-semibold;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    @include font-small;
  }
}
