@import "../../assets/scss/variables.scss";

.group .row {
  @media (min-width: $breakpoint-mobile) {
    display: flex;
    justify-content: space-between;

    margin: 50px 0;
  }
  @media (max-width: $breakpoint-mobile - 1) {
    // margin: 25px 0;
  }
}

.post {
  @media (min-width: $breakpoint-mobile) {
  }
  @media (max-width: $breakpoint-mobile - 1) {
    margin-top: 25px;
    margin-bottom: 100px;
  }
}

.row .post:first-child {
  @media (min-width: $breakpoint-mobile) {
    margin-right: 50px;
  }
}
